import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
// import { UserFileProvider } from '../contexts/UserFileContext';

// sample page routing
const Verification = Loadable(lazy(() => import('views/files/Verification')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Verification />
        },
        {
            path: '/verify',
            element: <Verification />
        }
    ]
};

export default MainRoutes;
